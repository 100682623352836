<template>
    <div style="text-align: center">
        <feather-icon icon="ArrowRightIcon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer"
                      @click="goToSetting"/>
    </div>
</template>

<script>
export default {
    name: 'CellRendererActions',
    methods: {
        goToSetting() {
            this.$router.push(`/user/register-employee-daily-work?branch_id=${this.params.data.organizationBranchId}&id=${this.params.data.employeeId}`).catch(() => {
            })
        }
    }
}
</script>
